<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            <h2
              :title="form.items.data[0].book_name"
              v-if="form.items.data.length > 0"
            >
              Book Log Records ({{
                form.items.data[0].book_name.wordLength(
                  form.items.data[0].book_name,
                  20
                )
              }})
            </h2>
            <h2 v-else>Book Log Records</h2>
            <v-spacer></v-spacer>
            <print-buttons
              v-if="$auth.can('library-download') && form.items.data.length > 0"
              :passAuthToReport="true"
              labelPdf="Download Pdf"
              labelExcel="Download Excel"
              :pdf="false"
              :excel="true"
              @action="downlaodBookLog()"
            >
            </print-buttons>
          </v-card-title>
          <v-card outlined>
            <v-card-title outlined>
              <v-flex xs6>
                <v-autocomplete
                  :items="book_status"
                  hide-no-data
                  class="pa-0"
                  label="Book Status"
                  :menu-props="{ top: true, offsetY: true }"
                  outlined
                  dense
                  chips
                  small-chips
                  clearable
                  deletable-chips
                  multiple
                  v-model="status"
                >
                  <template #selection="{ item }">
                    <v-chip
                      class="ma-2"
                      close
                      color="success"
                      label
                      outlined
                      @click:close="removeItem(item.value)"
                    >
                      {{ item.text }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-flex>

              <v-spacer></v-spacer>

              <v-flex xs2 style="float: left" v-if="status.length > 0">
                <search-button
                  permission="library-read"
                  @action="get()"
                  style="float: right"
                >
                  Search
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :loading="form.loading"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td align="left">{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left" @click="manual_book_code_modal = true,book_code_id=item.id, m_book_code = item.manual_book_code" style="cursor: pointer">
                  {{ item.book_code }}
                </td>
                <td class="text-xs-left" @click="manual_book_code_modal = true,book_code_id=item.id, m_book_code = item.manual_book_code" style="cursor: pointer">
                  {{ item.manual_book_code || "-" }}
                </td>
                <td class="text-xs-left">
                  {{ item.student_name || "-" }}

                  <div>
                    <span
                      style="font-size: 10px; color: #666"
                      v-if="item.enroll_code"
                    >
                      ({{ item.enroll_code || "" }})
                    </span>
                  </div>
                </td>
                <td class="text-xs-left">
                  {{ item.grade || "-" }}
                  <div>
                    <span
                      style="font-size: 10px; color: #666"
                      v-if="item.section"
                    >
                    ({{ item.section }})
                      <!-- ({{ item.enroll_code || "" }}) -->
                    </span>
                  </div>
                  
                </td>
                <td class="text-xs-left">
                  {{ item.roll || "-" }}
                </td>
                <td class="text-xs-left">
                  {{ item.issue_date || "-" }}
                </td>
                <td class="text-xs-left">
                  {{ item.issue_till_date || "-" }}
                </td>

                <td class="text-xs-left">
                  {{ item.renew_date || "-" }}
                </td>
                <td class="text-xs-left" v-if="item.status">
                  <v-chip
                    small
                    label
                    text-color="white"
                    :color="
                      item.issue_till_date_english.compareDate(
                        item.issue_till_date_english
                      )
                        ? 'error'
                        : item.status === 'issued'
                        ? 'primary'
                        : item.status === 'renewed'
                        ? 'amber'
                        : 'orange'
                    "
                    class="mt-1"
                  >
                    <!-- :color=" item.status === 'issued' ? 'primary' : item.status === 'returned'? 'amber' : 'orange'" -->
                    <!-- color= "primary" -->
                    {{ item.status.capitalizeFirstLetter() }}
                  </v-chip>
                </td>
                <td class="text-xs-left" v-if="item.status == null">-</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="manual_book_code_modal" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Manual Book Code</span>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-layout row wrap>
            <v-flex xs12 sm12>
              <br />
              <v-text-field
                autocomplete="off"
                label="Manual Book Code *"
                name="book_code"
                v-model="m_book_code"
                outlined
                dense
                required
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" outlined @click="resetManualBookCode()">Close</v-btn>
            <v-btn color="success" outlined  @click="saveManualBookCode()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Mixins from "@/library/Mixins";
import Form from "@/library/Form";

import {
  passDataToExternalDomain,
  redirectDownloadUrl,
} from "../../../../library/helpers";
export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        loading: false,
        name: "",
        enroll_code: "",
        status: "",
      },
      "library/api/book-log-record"
    ),
    status: ["issued", "renewed", "all"],
    book_status: [
      { text: "Issued", value: "issued" },
      // { text: "Returned", value: 'returned' },
      { text: "Renew", value: "renewed" },
      { text: "Others", value: "all" },
    ],
    headers: [
      { text: "#", align: "left", value: "sno", width: 5, sortable: false },
      { text: "Book Code", align: "left", value: "book_code", sortable: false },
      { text: "Manual Book Code", align: "left", value: "book_code", sortable: false },
      {
        text: "Student",
        align: "left",
        value: "student_name",
        sortable: false,
      },
      {
        text: "Grade (Section)",
        align: "left",
        value: "grade",
        sortable: false,
      },
      {
        text: "Roll",
        align: "left",
        value: "roll",
        sortable: false,
      },
      {
        text: "Issue Date",
        align: "left",
        value: "issue_date",
        sortable: false,
      },
      {
        text: "Issue Till Date",
        align: "left",
        value: "issue_till",
        sortable: false,
      },
      {
        text: "Renew Date",
        align: "left",
        value: "renew_date",
        sortable: false,
      },
      { text: "Status", align: "left", value: "status", sortable: false },
    ],
    manual_book_code_modal: false,
    m_book_code:'',
    book_code_id:'',

  }),

  watch: {
    pagination: function () {
      this.get();
    },
  },
  mounted() {},
  methods: {
    removeItem(val) {
      this.status = this.status.filter((el) => !val.includes(el));
    },
    get(params) {
      let extraparams =
        "book_status=" +
        this.status +
        "&book_id=" +
        (this.$route.query.book_id != undefined
          ? this.$route.query.book_id
          : "");
      let query = [null, undefined].includes(params)
        ? this.queryString(extraparams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    downlaodBookLog() {
      if (this.form.items.data.length > 0) {
        passDataToExternalDomain("reporting");
        setTimeout(() => {
          redirectDownloadUrl({
            uri: "/download/library/book-log-record",
            queryString: `book_status=${this.status}&book_id=${this.$route.query.book_id}`,
          });
        }, 300);
      }
    },
    saveManualBookCode() {
        if(this.m_book_code === '' ||  this.m_book_code === null ){
            return this.$events.fire("notification", {
              message: "Manual Book Code cannot be empty",
              status: "error",
            });
        }
        this.$rest.post('/library/api/manual-book-code',{
            manual_book_code:this.m_book_code,
            book_code_id:this.book_code_id,
            book_id:this.$route.query.book_id
        }).then((res)=>{
            this.$events.fire("notification", {
                message: res.data.message,
                status: "success",
            });
            this.resetManualBookCode();
            this.get();
        }).catch((err)=>{
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
        })
    },
    resetManualBookCode(){
        this.manual_book_code_modal = false;
        this.m_book_code='';
        this.book_code_id='';
    }
  },
};
</script>
